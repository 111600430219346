/*
This is a functional component called Home, which renders a Grid component from the Material UI library. 
The Grid component holds four sections of the website: Bio, About me, Social links, and Contact form.
Each section is wrapped inside a Grid item with a unique id attribute that corresponds to the href value of the a elements in the Navbar component. 
This allows the user to click on a link in the Navbar and be smoothly scrolled to the appropriate section on the page.
Each section also has its own component: Bio, AboutMe, SocialBlobs, and ContactForm. These components contain the content and styling for their respective sections.
The Box component from Material UI is used to center the content of each section vertically and horizontally in the viewport. 
The minHeight property of the Box ensures that each section takes up the full height of the viewport, regardless of the amount of content it contains.
*/

import React from 'react';
import '../App.css';
import SocialBlobs from '../components/SocialBlobs';
import Grid from '@mui/material/Grid';
import ContactForm from '../components/ContactForm';
import Bio from '../components/Welcome';
import AboutMe from '../components/aboutMe';
import { Box } from '@mui/material';

function Home() {
    return (
        // Grid container to hold all the sections
        <Grid container >

            {/* Bio section */}
            <Grid item xs={12} id="hello" >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh">
                    {/* Bio component */}
                    <Bio />
                </Box>
            </Grid>

            {/* About me section */}
            <Grid item xs={12} id="aboutme" >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh">
                    <div class='card'>
                        {/* AboutMe component */}
                        <AboutMe />
                    </div>
                </Box>
            </Grid>

            {/* Social links section */}
            <Grid item xs={12} id="links">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh">
                    {/* SocialBlobs component */}
                    <SocialBlobs />
                </Box>
            </Grid>

            {/* Contact form section */}
            <Grid item xs={12} id="contactSection">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh">
                    <div class='card'>
                        {/* ContactForm component */}
                        <ContactForm />
                    </div>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Home;
