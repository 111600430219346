// Import necessary modules
import React, { useState, useEffect } from "react";
import { db } from '../firebase';
import './ContactForm.css'
import { Grid } from "@mui/material";

// Define a functional component named Contact
const Contact = () => {

  // Declare and initialize the state variables
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);

  // Define a function to handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    // Save the form data to the Firestore database
    db.collection("contactme")
      .add({
        name: name,
        email: email,
        message: message,
      })
      .then(() => {
        setLoader(false);
        alert("Your message has been submitted 👍\nI will get back to you as soon as possible");
      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });

    // Clear the form input fields
    setName("");
    setEmail("");
    setMessage("");
  };

  // Render the Contact form
  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} md={6}>
        <div className="form">
          <h2>Looking for a new developer? Want to chat?</h2>
          <h3>Send me a message to get the process started.</h3>
          <a href="mailto:bradbomkamp@gmail.com"> bradBomkamp@gmail.com </a>
          <h3></h3>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <form className="form" onSubmit={handleSubmit}>
          <label>Name</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label>Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>Message</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button
            disabled={!message}
            className="glow-on-hover"
            type="submit"
            style={{ background: loader ? "#ccc" : " rgb(0, 0, 0)" }}
          >
            Send Message
          </button>
        </form>
      </Grid>
    </Grid>
  );
};

// Export the Contact component
export default Contact;
