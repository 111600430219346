// Importing necessary libraries
import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useMediaQuery } from '@material-ui/core';

// Defining a React component called Resume
function Resume() {
  // Setting the worker source of pdfjs
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  // Defining a style object for the container
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  };

  // Defining a style object for the button container
  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  };

  // Defining a boolean variable to check if the screen size is below 600px (for mobile devices)
  const isMobile = useMediaQuery('(max-width:600px)');

  // Returning the JSX to be rendered
  return (
    <div style={containerStyle}>
      {/* Container div with containerStyle */}
      <div>
        <Document file={`${process.env.PUBLIC_URL}/BradBomkampResume.pdf`}>
          {/* Document component with file prop that specifies the path to the PDF file */}
          <Page
            pageNumber={1} // Displaying the first page of the PDF file
            width={isMobile ? 290 : undefined} // If isMobile is true, set width to 290px, otherwise set it to undefined
            scale={isMobile ? 1.45 : undefined} // If isMobile is true, set scale to 1.45, otherwise set it to undefined
          />
        </Document>
      </div>
      {/* Button container div with buttonContainerStyle */}
      <div style={buttonContainerStyle}>
        <a href={`${process.env.PUBLIC_URL}/BradBomkampResume.pdf`} download>
          {/* Anchor tag with href pointing to the PDF file and download attribute */}
          Download PDF
        </a>
      </div>
    </div>
  );
}

// Exporting the Resume component as the default export
export default Resume;
