import firebase from "firebase";

let firestoreApp = firebase.initializeApp({
    
    apiKey: "AIzaSyCDH7koUFgmm0gGGCnuXe86GRgl30_kZzY",
    authDomain: "reactcontactform-78e6f.firebaseapp.com",
    projectId: "reactcontactform-78e6f",
    storageBucket: "reactcontactform-78e6f.appspot.com",
    messagingSenderId: "160654055811",
    appId: "1:160654055811:web:42c92a4a1e4645c5433eb6",
    measurementId: "G-4EK0PR7B62"
    
})

let db = firestoreApp.firestore();
 
export { db };