import { useState, useEffect } from 'react';
import './Welcome.css';
import { Grid } from '@mui/material';
import Pong from './pong';

function Welcome() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!isMobile && <Pong />}
      <Grid container>
        <Grid item xs={12}>
          <h4 className="boujee-text-2">Hello, my name is</h4>
        </Grid>
        <Grid item xs={12}>
          <h1 className="boujee-text">Brad Bomkamp</h1>
        </Grid>
        <Grid item xs={12}>
          <h4 className="boujee-text-2">I build things for the internet.</h4>
        </Grid>
        <Grid item xs={12}>
          <canvas id="pongCanvas" style={{ display: "none" }}></canvas>
        </Grid>
      </Grid>
    </>
  );
}

export default Welcome;
