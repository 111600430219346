import { useEffect, useRef } from "react";

const Pong = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Game settings
        const paddleWidth = 10;
        const paddleHeight = 100;
        const ballRadius = 8;

        // Game objects
        const leftPaddle = {
            x: 10,
            y: (canvas.height - paddleHeight) / 2,
            width: paddleWidth,
            height: paddleHeight,
            dy: 0,
        };

        const rightPaddle = {
            x: canvas.width - 10 - paddleWidth,
            y: (canvas.height - paddleHeight) / 2,
            width: paddleWidth,
            height: paddleHeight,
            dy: 0,
        };

        const ball = {
            x: canvas.width / 2,
            y: canvas.height / 2,
            radius: ballRadius,
            dx: 4,
            dy: 4,
        };

        // Score and opacity
        let score = 0;
        let opacity = 0;

        // Keyboard event handlers
        const keys = {};

        document.addEventListener("keydown", (e) => {
            keys[e.key] = true;
        });

        document.addEventListener("keyup", (e) => {
            keys[e.key] = false;
        });

        const update = () => {
            // Update left paddle position
            if (keys["w"] && leftPaddle.y > 0) {
                leftPaddle.y -= 5;
            }

            if (keys["s"] && leftPaddle.y + leftPaddle.height < canvas.height) {
                leftPaddle.y += 5;
            }

            // Update right paddle AI
            if (
                rightPaddle.y + rightPaddle.height / 2 < ball.y &&
                rightPaddle.y + rightPaddle.height < canvas.height
            ) {
                rightPaddle.y += 3;
            } else if (
                rightPaddle.y + rightPaddle.height / 2 > ball.y &&
                rightPaddle.y > 0
            ) {
                rightPaddle.y -= 3;
            }

            // Update ball position
            ball.x += ball.dx;
            ball.y += ball.dy;

            // Check ball collision with paddles
            if (
                (ball.x - ball.radius < leftPaddle.x + leftPaddle.width &&
                    ball.y > leftPaddle.y &&
                    ball.y < leftPaddle.y + leftPaddle.height) ||
                (ball.x + ball.radius > rightPaddle.x &&
                    ball.y > rightPaddle.y &&
                    ball.y < rightPaddle.y + rightPaddle.height)
            ) {
                const paddle = ball.x < canvas.width / 2 ? leftPaddle : rightPaddle;
                const relativeIntersection = (paddle.y + paddle.height / 2 - ball.y) / (paddle.height / 2);
                const angle = relativeIntersection * (Math.PI / 4); // Max angle: 45 degrees

                ball.dx = -ball.dx * 1.1; // Reverse and increase the horizontal speed by 10%
                ball.dy = -Math.sin(angle) * Math.abs(ball.dx); // Adjust vertical speed based on the angle
            }

            // Check ball collision with top and bottom walls
            if (ball.y - ball.radius < 0 || ball.y + ball.radius > canvas.height) {
                ball.dy *= -1
            }

            // Check ball out of bounds
            if (ball.x + ball.radius < 0 || ball.x - ball.radius > canvas.width) {
                // Update score if the right paddle misses the ball and it hits the right wall
                if (ball.x - ball.radius > canvas.width) {
                    score++;
                    opacity = 1;
                }

                // Reset score if the left paddle misses the ball
                if (ball.x + ball.radius < 0) {
                    score = 0;
                }

                // Reset ball
                ball.x = canvas.width / 2;
                ball.y = canvas.height / 2;
                ball.dx = 4; // Reset horizontal speed
                ball.dy = (Math.random() * 4) - 2;
            }
        };

        // Animate score
        const animateScore = () => {
            if (opacity > 0) {
                opacity -= 0.01;
            }
        };

        // Render game objects
        const render = () => {
            // Clear canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Render left paddle
            ctx.fillStyle = "white";
            ctx.fillRect(leftPaddle.x, leftPaddle.y, leftPaddle.width, leftPaddle.height);

            // Render right paddle
            ctx.fillStyle = "white";
            ctx.fillRect(rightPaddle.x, rightPaddle.y, rightPaddle.width, rightPaddle.height);

            // Render ball
            ctx.beginPath();
            ctx.arc(ball.x, ball.y, ball.radius, 0, Math.PI * 2);
            ctx.fillStyle = "white";
            ctx.fill();
            ctx.closePath();

            // Render score
            ctx.font = "30px Arial";
            ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
            ctx.textAlign = "center";
            ctx.fillText(`Score: ${score}`, canvas.width / 2, 50);

            // Update game state
            update();

            // Animate score
            animateScore();

            // Request the next frame
            requestAnimationFrame(render);
        };

        // Start game
        render();

        // Cleanup
        return () => {
            document.removeEventListener("keydown", (e) => {
                keys[e.key] = true;
            });
            document.removeEventListener("keyup", (e) => {
                keys[e.key] = false;
            });
        };
    }, []);

    return (
        <canvas
            ref={canvasRef}
            width={window.innerWidth}
            height={window.innerHeight}
            className="hide-on-mobile"
            style={{ position: "absolute", zIndex: -1 }}
        ></canvas>
    );
};

export default Pong;

