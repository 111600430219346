import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './pages/Home';
import Footer from "./components/Footer";
import Navbar from './components/Navbar';
import resume from './components/Resume';

function App() {
    return (
        <Router>
            <Navbar />
            <Switch>
                <Route exact path={'/'} component={Home} />
                <Route exact path={'/resume'} component={resume} />
            </Switch>
            <Footer />
        </Router>
    );
}

export default App;
