// Importing necessary libraries and stylesheets
import React from 'react';
import './aboutMe.css';
import { Box, Grid } from '@mui/material';
import artPic from '../artsyBrad.jpeg';
import { Typography } from '@mui/material';

// Defining a React component called AboutMe
function AboutMe() {
    // Returning the JSX to be rendered
    return (
        <div>
            {/* Grid container */}
            <Grid container>
                {/* Grid item with xs=12 and lg=4 */}
                <Grid item xs={12} lg={4}>
                    {/* Box component with display, alignItems, and justifyContent props */}
                    <Box display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <img src={artPic} alt="Avatar" width='400px' borderRadius='50%' />
                    </Box>
                </Grid>

                {/* Grid item with xs=12 and lg=8 */}
                <Grid item xs={12} lg={8} padding='5%' textAlign={"center"}>
                    {/* Typography component with color prop */}
                    <Typography color={"white"}>
                        {/* Three paragraphs of text */}
                        <p>Hi there! My name is Brad Bomkamp, and I am a computer science graduate from the University of Wisconsin Parkside. During my time in college, I had the opportunity to intern at The App Factory, where I gained valuable experience working with various technologies, such as React.js, JavaScript, CSS, HTML, Java, Swift, and Python.</p>
                        <br />
                        <p>In my current role, I specialize in web design and have a passion for creating intuitive and engaging user experiences. I am always looking for ways to improve my skills and stay up-to-date with the latest developments in the field.</p>
                        <br />
                        <p>I am excited to continue my professional journey and contribute my skills and knowledge to a dynamic team. Thank you for considering me for your project or opportunity.</p>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

// Exporting the AboutMe component as the default export
export default AboutMe;
