import React from "react";
import './SocialBlobs.css';
import { Grid } from "@mui/material";

function SocialBlobs() {


    return (
        <Grid container spacing={3} alignItems={"center"} justifyContent={"center"} >

            <Grid item>
                <div class="square linkedin">
                    <span></span>
                    <span></span>
                    <span></span>
                    <div class="content">
                        <h2>
                            LinkedIn
                        </h2>
                        <p>This is where I network and build my professional portfolio</p>
                        <a href="https://www.linkedin.com/in/brad-bomkamp/" target="_blank">_bradBomkamp</a>
                    </div>
                </div>
            </Grid>

            <Grid item>
                <div class="square twitter">
                    <span></span>
                    <span></span>
                    <span></span>
                    <div class="content">
                        <h2>
                            Twitter
                        </h2>
                        <p>This is where I read news and network with different social groups</p>
                        <a href="https://twitter.com/BradBomkamp" target="_blank">@bradBomkamp</a>
                    </div>
                </div>
            </Grid>

            <Grid item>
                <div class="square github">
                    <span></span>
                    <span></span>
                    <span></span>
                    <div class="content">
                        <h2>
                            Github
                        </h2>
                        <p>This is where I share code and work on projects</p>
                        <a href="https://github.com/bbomkamp" target="_blank">bBomkamp</a>
                    </div>
                </div>
            </Grid>

        </Grid>
    )
}
export default SocialBlobs;