/*
This code defines a React component called "Footer". It imports some necessary dependencies, 
such as React itself, "react-router-dom" for linking, and "Grid" from MUI (Material UI).
The component returns a Grid container, which includes a section of social media icons and a small text that shows the website name and the current year. 
The social media icons are implemented using the "Link" component from "react-router-dom" to link to different social media profiles of the website owner. 
Each icon is represented by an "i" tag with a specific "className" for each social media platform.

The "Footer" component is then exported as the default export of this module.
*/


// Import necessary modules
import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

// Define the functional component
function Footer() {

    // Render the component
    return (
        <Grid container>
            <Grid item xs={12}>
                <div className='footer-container'>

                    <section className='social-media'>
                        <div className='social-media-wrap'>

                            {/* Display website rights */}
                            <small className='website-rights'>bradBomkamp © 2023</small>
                            {/* Display social media icons and links */}
                            <div className='social-icons'>

                                <Link
                                    class='social-icon-link twitter'
                                    to={{ pathname: "https://www.linkedin.com/in/brad-bomkamp/" }}
                                    target='_blank'
                                    aria-label='LinkedIn'
                                >
                                    <i className='fab fa-linkedin' />
                                </Link>

                                <Link
                                    class='social-icon-link twitter'
                                    to={{ pathname: "https://github.com/bbomkamp" }}
                                    target='_blank'
                                    aria-label='Github'
                                >
                                    <i className="fab fa-github" />
                                </Link>

                                <Link
                                    class='social-icon-link youtube'
                                    to={{ pathname: "https://www.youtube.com/channel/UCzBNQiDIoXESAhQP-JKRdOA" }}
                                    target='_blank'
                                    aria-label='Youtube'
                                >
                                    <i className='fab fa-youtube' />
                                </Link>

                                <Link
                                    class='social-icon-link twitter'
                                    to={{ pathname: "https://twitter.com/BradBomkamp/" }}
                                    target='_blank'
                                    aria-label='Twitter'
                                >
                                    <i className='fab fa-twitter' />
                                </Link>
                            </div>
                        </div>
                    </section>
                </div>
            </Grid>
        </Grid>
    )
}

// Export the component for use in other files
export default Footer;
