import React, { useState, useEffect } from 'react' // import React and the useState and useEffect hooks
import './Navbar.css' // import the CSS styles for the Navbar component
import { Link } from 'react-router-dom'; // import the Link component from React Router
import { scroller } from 'react-scroll'; // import the scroller function from the react-scroll library

export default function Navbar() { // define a function component called Navbar

  const [scrollPosition, setScrollPosition] = useState(0); // initialize a state variable called scrollPosition to 0 using the useState hook

  useEffect(() => { // define a side effect using the useEffect hook
    const handleScroll = () => { // define a function called handleScroll that sets the scroll position when the window is scrolled
      const position = window.pageYOffset; // get the current scroll position of the window
      setScrollPosition(position); // update the scrollPosition state variable with the new position
    };

    window.addEventListener('scroll', handleScroll); // add an event listener for the scroll event that calls handleScroll
    return () => { // clean up the event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // specify an empty dependency array to ensure that the side effect only runs once

  const navbarClass = scrollPosition > 400 ? 'nav nav-scroll' : 'nav'; // determine the CSS class for the navbar based on the scroll position

  const handleClick = (to) => { // define a function called handleClick that scrolls to a section of the page or navigates to a different URL depending on the current page
    if (window.location.pathname === "/resume") { // if the current page is the resume page
      window.location.href = `/#${to}`; // navigate to the specified section of the page using a hash fragment
    } else { // otherwise
      scroller.scrollTo(to, { // scroll to the specified section of the page using the scroller function from react-scroll
        smooth: true, // animate the scroll
        duration: 250, // set the duration of the scroll animation to 250 milliseconds
      });
    }
  };

  return (
    <div className={navbarClass}>
      <div class="nav">
        <input type="checkbox" id="nav-check" />
        <div class="nav-header">
          <div class="nav-title">
          <a href="#hello" onClick={() => { handleClick("hello"); document.getElementById("nav-check").checked = false }}>
              bradBomkamp.com
            </a>
          </div>
        </div>
        <div class="nav-btn">
          <label for="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <div class="nav-links">
          <a onClick={() => { handleClick("aboutme"); document.getElementById("nav-check").checked = false }}>
            aboutMe
          </a>
          <a onClick={() => { handleClick("links"); document.getElementById("nav-check").checked = false }}>
            links
          </a>
          <a onClick={() => { handleClick("contactSection"); document.getElementById("nav-check").checked = false }}>
            contactMe
          </a>
          <Link to="/resume" onClick={() => document.getElementById("nav-check").checked = false}>
            myResume
          </Link>
        </div>
      </div>
    </div>
  )
}

